.card-body__custom_card__container {
    @include directify($directions) {
        text-align: directed('left');
    }

    min-height: 75px;
    background: #232329;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    overflow: hidden;

    .span__custom_card__svg svg {
        position: absolute;
        height: 100px;
        width: 100px;
        top: calc(50% - 40px);
        opacity: 0.3;
        @include directify($directions) {
            #{directed('right')}: -23px;
        }
        color: #ffffff;
    }

    .p__custom_card__container-title {
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 10px;
    }

    .p__custom_card__container-subhead {
        margin-bottom: 2px;
        font-size: 12px;
        line-height: 1.25;
        color: #dddddd;
        max-width: 100%;
        opacity: 0.7;
    }

}

.badge--event-has-rule {
    float: right;
    position: absolute;
    top: 0;
    right: 10px;
}

.badge--event-has-report-and-rule {
 @extend .badge--event-has-rule;
    top: 35px;
}