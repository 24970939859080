.tooltip {
  z-index: 99;

  .tooltip-inner {
    background-color: #adb7c5;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 15px;
  }

  &.bs-tooltip-right .arrow::before,
  &.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #adb7c5;
  }

  &.bs-tooltip-top .arrow::before,
  &.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #adb7c5;
  }

  &.bs-tooltip-bottom .arrow::before,
  &.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #adb7c5;
  }

  &.bs-tooltip-left .arrow::before,
  &.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #adb7c5;
  }
}

.card-tooltip {
  @media (min-width: 1426px) {
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1200px) and (max-width: 1425px) {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.button-tooltip {
  margin-left: auto!important;
  margin-right: auto!important;
}

.button-tooltip-demo {
  @extend .button-tooltip;
  padding: 5px;
  margin: auto 15px auto auto;
}

.button-tooltip-alert {
  @extend .button-tooltip;
  padding: 5px;
  margin: auto 15px auto auto;
}
