.list-group-item {
  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  @include themify($themes) {
    color: themed('colorText');
  }

  border: 0;

  cursor: pointer;
}

.list-group-item.active {
  background-color: #383740;
  border: 0;
  border-left: 3px solid #4ce1b6;
}

.listgroup__profile {
  margin-bottom: 30px;
  border-radius: 5px;

}