.mitre__wrapper {
  overflow-x: scroll;
  text-align: center;
  font-size: 11px;
  padding-bottom: 30px;
  line-height: 1.4;

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  .mitre__table_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 3px;
  }

  .mitre__tactic-item-empty {
    width: 100%;
    min-width: 90px;
    margin: 0 1px;
    border: 1px solid transparent;
    padding: 3px;
  }

  .mitre__tactic-item {
    @extend .mitre__tactic-item-empty;
    border: 1px solid rgb(76, 225, 182);
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &-legend {
      @extend .mitre__tactic-item;
      background: rgb(76, 225, 182);

      p {
        color: #232329;
      }
    }

    &-pointer {
      @extend .mitre__tactic-item;
      cursor: pointer;
    }

    &-selected {
      @extend .mitre__tactic-item-pointer;
      background: rgb(76, 225, 182);

      p {
        color: #232329;
      }
    }
  }

}