.popover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: none;
  background-color: transparent;
  border-radius: 4px;
  width: 220px;
  z-index: 110;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  .popover-header {
    border: none;
    background-color: #f2f4f7;
    font-weight: 700;
    font-size: 11px;
    padding: 5px 10px;
    line-height: normal;
  }

  .popover-body {
    font-size: 10px;
    @include directify($directions) {
      #{directed('padding-right')}: 15px;
      #{directed('padding-left')}: 10px;
    }
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .arrow::before {
    border-bottom-color: #2a2a31 !important;
  }

  .arrow::after {
    border-bottom-color: #2a2a31 !important;
  }
}

.popover-demo, .popover-alert, .popover-warning {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  background-color: transparent;
  width: 220px;
  z-index: 110;
  color: white;

  .popover-header {
    font-weight: 700;
    font-size: 13px;
    padding: 5px 15px;
    line-height: normal;
    background-color: #2a2a31;
    color: #f6da6e;

    &::before {
      border-bottom: 1px solid #2a2a31 !important;
    }
  }

  .popover-body {
    font-size: 12px;
    @include directify($directions) {
      #{directed('padding-right')}: 15px;
      #{directed('padding-left')}: 10px;
    }
    padding: 15px;
    background-color: #232329;
    color: white;
  }
}

.popover-alert {
  .popover-header {
    color: #ff4861;
  }

}

.button-tooltip-alert {
  svg {
    margin: 0 !important;
    width: 24px;
    height: 24px;
  }
}
