.img__vt-icon {
  width: 21px;
  height: 21px;
  padding: 3px;
}

.img__darkweb-icon {
  height: 400px;
  position: absolute;
  bottom: 50px;
}

.img__investigate-icon {
  height: 300px;
  position: absolute;
  bottom: 80px;
}
