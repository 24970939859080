.fakeItem {
    display: inline-block;
    position: relative;
    height: 1em;
    font-size: 13px;
    background: #4f4f4f;
    overflow: hidden;
}

.fakeItem:after {
    overflow: hidden;
    width: 50%;
    height: 100%;
    content: "";
    position: absolute;
    background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0),
            rgba(255, 255, 255, 0.18),
            rgba(0, 0, 0, 0)
    );
    transform: translateX(-100%);
    animation: loading 1s infinite;
}

.row--indicator {
    [class*="col-"] {
        padding-left: 8px;
        padding-right: 4px;
    }
}

.row--indicator:hover {
    background-color: #4f4f4f;
}
