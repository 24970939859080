#node_tool_tip {
  position: absolute;
  z-index: 10;
  display: none;
  background: #232329;
  width: calc(100% - 40px);
  margin-right: 20px;
  color: white;

  .preCircle {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5px
  }
}

.btn_actions {
  flex-direction: column;
  display: flex;
  position: absolute;
  height: calc(100% - 30px);
  justify-content: center;
  align-items: center;
  top: 0;

  button {
    padding: 0px;
    margin: 0px;
    margin-bottom: 5px;
    margin-right: 0px !important;
    width: 30px;
    height: 27px;
    border-radius: 3px;
  }
}
