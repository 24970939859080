.sandbox__score__results-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .sandbox__score__results-chart {
    position: relative;
    text-align: center;
  }

  .sandbox__score__results-label {
    position: absolute;
    font-size: 14px;
    line-height: 20px;
    top: calc(50% - 30px);
    width: 100%;
    margin: 0;
    animation: label 1.5s ease-in;
    color: #dddddd;
  }

  .sandbox__score__results-label-status {
    position: absolute;
    font-size: 16px;
    line-height: 20px;
    top: calc(50% - 10px);
    width: 100%;
    margin: 0;
    animation: label 1.5s ease-in;
    color: #dddddd;
  }

  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    margin-top: 20px;
    font-size: 16px;

    .sandbox__score__results-chart {
      margin-bottom: 15px;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1539px) {

    .sandbox__score__results-chart {

      svg, div {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
}