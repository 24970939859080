.img__resource-monitor-icon {
  height: 300px;
  position: absolute;
  bottom: 100px;
}

// booking

.resource_monitor-card {
  padding-top: 20px;
  padding-bottom: 25px;
  padding-right: 30px;
  padding-left: 30px;

  .resource_monitor-card-progress-label {
    @include directify($directions) {
      text-align: directed('right');
    }

    font-size: 14px;
    font-weight: 500;
  }

}

.resource_monitor-card-progress-description {
  @include directify($directions) {
    text-align: directed('left');
  }

  opacity: 0.7;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: #555555;
  margin-top: 3px;
  text-transform: uppercase;

  .theme-dark & {
    color: #dddddd;
  }
}

.resource_monitor-title {
  direction: ltr;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  color: $color-accent;
}

.resource_monitor-total-title--red {
  color: $color-red !important;
}

.resource_monitor-total-title--dark-red {
  color: #D50000 !important;
}

.resource_monitor-total-title--green {
  color: $color-green;
}

.resource_monitor-total-title--blue {
  color: $color-blue;
}

.resource_monitor-total-title--yellow {
  color: #fcda53;
}

.resource_monitor-total-container {
  display: flex;
  justify-content: space-between;
}

.resource_monitor-title-total {
  font-size: 16px;
}

.resource_monitor-gauge-card {
  height: 325px !important;

  .chart__gauge__wrapper {
    margin-top: 70px;
  }
}

.resource_monitor-card-empty-result-title {
  margin-bottom: 0;
}